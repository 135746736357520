<template>
  <div>
    <div class="left">
      <div>
        <div class="img">
          <img src="@/assets/imgs/toux.png" alt="" width="100%" height="110%">
            <!-- <div v-if="isFillTimeFlag" class="nochangeText">*高考报考期间，不可修改成绩</div> -->
        </div>
        <!-- <div class="text">
          <div>{{ personForm.scoreEditNum }}</div>
          <div>分数剩余修改次数</div>
        </div> -->
      </div>
    </div>
    <div class="right" v-loading="loading">
      <el-form ref="personForm" :model="personForm" label-width="80px" style="width:60%; ">
        <el-form-item label="姓名">
          <el-input size="medium " clearable v-model.number="personForm.studentName" :disabled="false" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="高考年份">
          <!-- <el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd" style="width:100%" v-model="personForm.graduationTime" type="date" :disabled="false"
            placeholder="选择日期"></el-date-picker> -->
          <el-input size="medium "  :disabled="true" v-model="expirationTime" ></el-input>

        </el-form-item>
        <el-form-item label="毕业地区">

          <el-select   :disabled="false" placeholder="请选择毕业地区" v-model="personForm.graduationArea" style="width: 100%;display:inline-block">
            <el-option :label="item.name" :value="item.name" v-for="item in graduationAreaList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="就读高中">
          <el-input size="medium " clearable v-model="personForm.graduationSchool" :disabled="false" placeholder="请输入就读高中"></el-input>
        </el-form-item>
        <el-form-item label="分数">
          <el-input size="medium " clearable v-model.number="personForm.score" :disabled="inputDis" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入高考分数"></el-input>
        </el-form-item>
        <!-- <el-form-item label="年级">
          <el-select style="width:100%;" :disabled="disabled" v-model="personForm.studentClass" placeholder="请选择年级">
            <el-option label="高三" value="高三"></el-option>
            <el-option label="高二" value="高二"></el-option>
            <el-option label="高一" value="高一"></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div style="margin-bottom: 20px; ">
        <el-row>
          <el-col :span="1">
            <div class="grid-content bg-purple"> </div>
          </el-col>
          <el-col :span="15">
            <div class="grid-content bg-purple">主选科目<span style="font-size: 12px;">(创建成功后，主科目不可修改)</span></div>
          </el-col>
        </el-row>
        <div class="km">
          <div v-for="(item, index) in  op" @click="handclick(index)" :key="index" :class="{ bg: ops.includes(index) }">
            {{ item.label }}
          </div>
        </div>
      </div>
      <el-row>
        <el-col :span="4">
          <div class="grid-content bg-purple"> </div>
        </el-col>
        <el-col :span="1">
          <div class="grid-content bg-purple"> </div>
        </el-col>
        <el-col :span="3" style="margin-left: 4%;width: 80%;">
          <div class="grid-content bg-purple">次选科目</div>
        </el-col>
      </el-row>
      <div class="km">
        <div v-for="(item, index) in  op2 " :key="index" @click="handclick2(item)" :class="{ bg: item.show }">
          {{
            item.label }}
        </div>

      </div>
      <div class="btn">
        <!-- <el-button type="primary" style="width: 30%;" round @click="set">修改</el-button> -->
        <el-button type="primary" style="width: 30%;" round  @click="set1" :disabled="false">确定修改</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import { getUserInfo, getUserInfoCopy, REGUserEditNoPass, } from '../../api/user'
export default {
  name: 'comzhx1',
  data() {
    return {
      disabled: true,
      loading:true,
      radio: '1',

      op: [
        { value: '0', label: '物理' },
        { value: '1', label: '历史' },
      ],
      ops: [],
      subject: [],
      optionalSubject: [],
      op2: [
        { value: '0', label: '生物',show:false },
        { value: '1', label: '地理',show:false },
        { value: '2', label: '化学',show:false },
        { value: '3', label: '政治',show:false },
      ],
      ops2: [],
      graduationAreaList: [{
        name: '石家庄',
        id: 37
      }, {
        name: '唐山',
        id: 38
      }, {
        name: '秦皇岛',
        id: 39
      }, {
        name: '邯郸',
        id: 40
      }, {
        name: '邢台',
        id: 41
      }, {
        name: '保定',
        id: 42
      }, {
        name: '张家口',
        id: 43
      }, {
        name: '承德',
        id: 44
      }, {
        name: '沧州',
        id: 45
      }, {
        name: '廊坊',
        id: 46
      }, {
        name: '衡水',
        id: 47
      }],
      accountNumber: [],
      userAllInfo: {},
      personForm: {},
      expirationTime:'',
      inputDis:false,
      subjectDis:false,
      optionSubjectDis:false,
      isFillTimeFlag:false,
    }
  },
  methods: {
    handclick(e) {
        if(this.subjectDis){
              return this.$message.warning('科目已经确认，无法修改')
        }
        this.ops = []
        this.subject = []
        this.ops.push(e)
        this.op.forEach(item => {
          if (item.value == e) {
            this.subject.push(item.label)
          }
        })

    },
    handclick2(item) {
        if(this.optionSubjectDis){
        return
      }
          item.show = !item.show
    },
    set() {
      this.disabled = !this.disabled
    },
    set1() {
      const objCopy = JSON.parse(JSON.stringify(this.personForm))
      delete objCopy.password // 删除密码字段，后端不需要
      if (!objCopy.score) {
        this.$message.warning('请输入高考分数')
        return
      }
      if (!objCopy.studentName) {
        this.$message.warning('请输入姓名')
        return
      }
      if (isNaN(Number(objCopy.score))) {
        this.$message.warning('请输入准确的高考分数')
        return
      }
      if (!(Number(objCopy.score) >= 1 && Number(objCopy.score) <= 750)) {
        this.$message.warning('请输入准确的高考分数')
        return
      }
      if(!this.subject[0]){
        this.$message.warning('请选择主选科目')
        return
      }
      let optionalSubject = this.op2.filter(item=>item.show).map(item=>item.label)
      if(optionalSubject.length!=2){
        return this.$message.warning('次选科目请选择两个')
      }
      objCopy.subject = this.subject[0]   //主选科目，物理或历史
      objCopy.optionalSubject = JSON.stringify(optionalSubject)    // 次选科目，

      REGUserEditNoPass(objCopy).then( async res => {
        if (res.code == 200) {
          this.onload()
          this.disabled = !this.disabled
          this.$message.success('修改成功')
          // await this.$store.dispatch('setUserinfo') // 重新获取个人信息
        }
      })
    },
    async onload() {
      this.loading = true
      const phonenumber =  localStorage.getItem('phonenumber')
       if(!phonenumber){ return this.loading = false }
       await this.$store.dispatch('setUserinfo') // 重新获取个人信息
       this.personForm = this.$store.state.userInfo;
        this.expirationTime = this.personForm.expirationTime  ? this.personForm.expirationTime.split('-')[0] : ''
         this.loading = false
        this.optionalSubject = []
        let arr = this.personForm.optionalSubject ? JSON.parse(this.personForm.optionalSubject) : []
        this.ops = []
        this.ops2 = []
        // 首选科目进行样式active
        this.op.forEach(item => {
          if (item.label === this.personForm.subject) {
            this.ops.push(Number(item.value))
            this.subject.push(item.label)
          }
        })
        // 次选科目进行样式active
        this.op2.forEach(item => {
          arr.forEach(itemm => {
            if (item.label === itemm) {
                item.show = true
            }
          })
        })
        // 判断用户是否可以修改 分数+选科
         let promiChange = await this.isChangeScoreOrSubject(this.personForm.score,this.personForm.isUpdateScore,this.personForm.subject,this.personForm.trans)
          this.inputDis = promiChange.inputDis
          this.subjectDis = promiChange.subjectDis
          this.optionSubjectDis = promiChange.optionSubjectDis
          this.isFillTimeFlag = promiChange.isFillTimeFlag

    }
  },
  async mounted() {
    // 字典手机号用户
    await this.getDicts('sys_account_number').then(res => {
      res.data.forEach(item => {
        this.accountNumber.push(item.dictValue)
      })
    })
    this.onload()
  }
}
</script>

<style lang="scss" scoped>
.left {
  width: 25%;
  // height: 600px;
  float: left;
  margin-top: 30px;
}

.right {
  width: 72%;
  height: 600px;
  float: right;
  margin-top: 30px;
}

.img {
  width: 60%;
  height: 60%;
  border: 2px solid #c3ecd4;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin: auto;
  cursor: pointer;
}

.img span {
  display: inline-block;
  position: absolute;
  top: 75%;
  left: 1%;
  width: 100%;
  height: 40px;
  background-color: #d6d6d6a8;
  text-align: center;
  font-size: 15px;
}

.text {
  width: 100%;
  height: 40%;
  text-align: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}

.text div:nth-child(1) {
  font-size: 38px;
  margin: 10%;
}

.el-row {
  margin-top: 5%;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
}

.km {
  width: 100%;
  margin-left: 1.5%;
}

.km div {
  width: 15%;
  height: 30px;
  border-radius: 10px;
  margin: 10px;
  float: left;
  color: #000000;
  background-color: #cfcfcf;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.bg {
  color: #fff !important;
  background-color: #12b098 !important;
}

.btn {
  width: 80%;
  margin-top: 10%;
  display: flex;
  //  justify-content: space-evenly;

  margin-left: 3%;
}
</style>
